#modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  #modal-background {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.692);

  }

  #modal-content {
    display: flex;
    align-items: center;
    justify-content: row;
    position: absolute;
    /* background-color: white; */
    height: auto;
  }
