/* TODO Add site wide styles */
*::-webkit-scrollbar {
    display: none;
}

*{
    margin: 0px;
    padding: 0px;
    font-family: 'Noto Sans', sans-serif;
    /* width: 100%; */
}

/* html {
    width: 100%;
} */