.page {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    background-color: #dae0e6;
    background-repeat: no-repeat;
    background-size: cover;
}

.right-bottom-post {
    display: flex;
    height: 38px;
    align-items: center;
    margin-top: 2px;
    width: 562px;
}

.votess {
    background-color: white;
}

/* .votes:visited {
    background-color: red;
} */

.votesss {
    display: flex;
    justify-content: center;
    height: 20px;
    width: 100%;
    background-color: white;
    border: transparent;
}

#votes {
    display: flex;
    justify-content: center;
}

.main-feed-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 30px 10px 60px;
    width: fit-content;
}

.img-tage{
    margin-top: 20px;
    margin-left: 20px;
    height: 425px;
    width: 700px;
    border-radius: 4px;
}

.main-page-image {
    margin-top: 8px;
    /* margin-left: 2px; */
    height: 370px;
    width:562px;
    /* border-radius: 4px;  */
}

.post {
    display: flex;
    box-shadow: 0px 0px 4px 3px rgba(216, 214, 214, 0.918);
    box-shadow: 3px;
    border-radius: 2px;
    margin: 0px 0px 20px;
    width: 600px;
    height: auto;
    background-color: white;
    /* border: -1px transparent white; */
}

.post:hover {
    /* display: inline-block; */
    border: 1px solid rgba(0, 0, 0, 0.4);
    margin: -1px -1px 19px;
    
}

.right-container {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    height: auto;
    width: 266px;
    margin: 30px 10px;
}

.comm {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 266px;
    border-radius: 2px;
}

.create-title h2 {
    display: flex;
    color: #929596;
    padding-top: 40px;
}

.post-containerr {
    background-color: #ffffff;
    height: 336px;
    box-shadow: 0px 0px 4px 2px rgb(216 214 214 / 92%);
    border-radius: 6px;
    width: 600px;
    margin-right: 10px;
}

.create a {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    color: gray;
}

.links a {
    margin-bottom: 10px;
}

.links a:hover {
    margin-bottom: 10px;
    color: black;
}

.links {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 20px 8px;
}

.links a {
    margin-right: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

#main-upvote-btn {
    /* margin-top: 4px; */
    height: 39px;
}

#main-upvote-btn:hover {
    /* margin-top: 10px; */
    background-color: rgb(228, 228, 228);
    border: 1px solid rgb(228, 228, 228);
}

#main-downvote-btn:hover {
    /* margin-top: 10px; */
    background-color: rgb(228, 228, 228);
}

#main-upvote{
    /* color: red; */
    width: 36px;
    height: 25px;
}

#main-downvote-btn {
    /* margin-top: 10px; */
    height: 39px;
}

#main-downvote{
    margin: 0px;
    width: 36px;
    height: 25px;
}

.left-post {
    display: flex;
    flex-direction: column;
    align-items: center;
    float: left;
    height: 100%;
    width: 37px; 
    border-radius: 4px;
    border-right: 1px solid rgb(233, 233, 233);
    background-color: rgba(248, 248, 248, 0.513);
}

.right-post {
    display: flex;
    flex-direction: column;
    height: 99%;
    width: 89%;
    text-align: center;
}

.right-postt {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 99%;
    width: 100%;
    text-align: center;
}

.post-title {
    margin-top: 10px;
}

.left-post button {
    border: 1px solid rgba(248, 248, 248, 0.513);
    background-color: rgba(248, 248, 248, 0.513);
    /* border-radius: 6px; */
}

#upvotee {
    background-color: rgba(248, 248, 248, 0.513) !important;
}

.left-post .votesss {
    border: 1px solid rgba(248, 248, 248, 0.513);
    background-color: rgba(248, 248, 248, 0.513);
    
}

.comm h1 {
    display: flex;
    justify-content: center;
    text-align: center;
}

.left-post button:hover {
    cursor: pointer;
}

.button-div {
    display: flex;
    justify-content: flex-start;
    border-radius: 4px;
}


.comm-header {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 50px;
    background-image: url("https://thumbs.dreamstime.com/b/group-pets-together-15229056.jpg");
    border-radius: 2px;
}

.rows {
    border: 1px solid rgba(216, 214, 214, 0.918);
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    color: green;
}

.rows .fa-solid {
    margin-left: 60px;
    margin-right: 30px;
}

.communities {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 2px;
    position: static;
    box-shadow: 0px 0px 4px 1px rgba(216, 214, 214, 0.918);
    margin-bottom: 15px;
    background-color: white;
    color: rgb(104, 97, 97);
}

.create {
    display: flex;
    flex-direction: column;
    height: 20vh;
    width: 100%;
    box-shadow: 0px 0px 4px 1px rgba(216, 214, 214, 0.918);
    border-radius: 4px;
    background-color: white;   
    border: 1px solid rgba(248, 248, 248, 0.513);
    text-align: center;
    color: rgb(104, 97, 97);
}

.right-post a {
    text-decoration: none;
    width: max-content;
    height: 376px;
}

.button-div {
    display: flex;
    justify-content: flex-end;
    margin-left: 5px;
}

#main-edit-btn-icon {
    height: 27px;
    width: 20px;
    margin-right: 6px;
}

#main-delete-btn-icon {
    height: 27px;
    width: 22px;
    margin-right: 6px;
}

.elephant-doodle {
    z-index: 100;
    display: flex;
    justify-content: center;
    width: 500px;
    height: 500px;
    background: rgba(216 214 214 / 0);
}

.edit-btn {
    font-size: 20px;
}

.edit-btn,
.delete-btn {
    height: 22px;
    width: 77px;
    margin-right: -10px;
    cursor: pointer;
    margin-bottom: 7px;
    font-weight: 200;
    font-size: 10px;
    /* font-style: nomal !important; */
    margin-bottom: 17px;

}
.main-votes-div {
    padding-left: 1px;
}

.edit-btn button {
    display: flex;
    align-items: center;
    font-size: 12px;
    letter-spacing: .3px;
    border: none;
    background-color: white;
    padding-left: 9px;
    margin-top: 1px;
    /* padding-right: 10px; */
    /* padding-top: 4px; */
    /* margin-bottom: 4px; */
    height: 37px;
    width: 84px;
  }
.delete-btn button {
    display: flex;
    align-items: center;
    font-size: 12px;
    letter-spacing: .3px;
    border: none;
    background-color: white;
    padding-left: 9px;
    /* padding-right: 10px; */
    /* padding-top: 4px; */
    margin-top: 1px;
    height: 37px;
    width: 84px;
  }

.edit-btn button:hover,
.delete-btn button:hover {
    cursor: pointer;
    background-color: rgb(228, 228, 228);
}

.left-post img {
    border-radius: 4px;
    height: 30px;
    width: 30px;
    background-color: rgba(248, 248, 248, 0.513);
}


@media screen and (max-width: 950px){

    .right-container {
        display:none;
    }

    .right-bottom-post {
        width: auto;
    }

    .post {
        display: flex;
        box-shadow: 0px 0px 4px 3px rgb(216 214 214 / 92%);
        box-shadow: 3px;
        border-radius: 2px;
        margin: 0px 0px 20px;
        width: 23em;
        height: auto;
        background-color: white;
    }

    .main-page-image {
        margin-top: 8px;
        /* margin-left: 2px; */
        height: 15em;
        width: 20.6em;
        /* border-radius: 4px;*/
    }

    .right-post a {
        text-decoration: none;
        width: max-content;
        height: auto;
      }

      .main-feed-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 30px 0px 60px 12px;
        width: fit-content;
    }

}
