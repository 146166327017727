.comment-on-comment-form {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    background-color: transparent;
    width: -webkit-fill-available;
    /* padding: 0px 63px 0px 63px; */
    /* margin-bottom: 20px; */
    /* margin-top: 20px; */
    /* margin-left: 41px; */
  }

  .comment-on-comment-form textarea {
    display: flex;
    resize: none;
    width: -webkit-fill-available;
    height: 130px;
    border-radius: 4px 4px 0px 0px;
    padding: 7px 10px;
    /* border: 1px solid #edeff1; */
    border-top: 1px solid #edeff1;
    border-right: 1px solid #edeff1;
    border-left: 1px solid #edeff1;
    border-bottom: none;
    resize: vertical;
  }

  .comment-on-comment-textarea {
      display: flex;
      width: 100%;
      /* margin-top: ; */
  }

  .comment-on-comment-textarea-bottom{
    display: flex;
    justify-content: flex-end;
    height: -webkit-fill-available;
    /* width: 669px; */
    border: 1px solid #edeff1;
    background-color: #edeff1;
}