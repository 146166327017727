.main-feed-comment-btn {
    display: flex;
    align-items: center;
    font-size: 15px;
    border: none;
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    height: 38px;
}

.main-feed-comment-btn:hover {
    cursor: pointer;
    background-color: rgb(228, 228, 228);
}

.main-comment-btn-label {
    display: flex;
    text-align: center;
    padding-bottom: 5px;
    font-size: 12px;
}

.comment-count {
    margin-left: 3px;
}

#comment-count-icon {
    width: 22px;
    height: 20px;
}

.comment-btn-text {
    display: flex;
    margin: 14px 0px 0px 3px;
    height: fit-content;
}