#page {
    height: 97vh;
    width: 100%;
    background-color: red;
    background-image: url("https://t2.ea.ltmcdn.com/en/posts/6/8/9/happy_dog_names_male_and_female_2986_orig.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
}

#buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#asdf {
    margin-bottom: 400px;
    text-align: center;
}

#asdf h1 {
    margin-bottom: 20px;
}

#asdf p {
    width: 300px;
    word-wrap: break-word;
    margin-bottom: 20px;
}

#view-all {
    width: 85%;
    border-radius: 20px;
    height: 30px;
    color: white;
    background-color: #0079d3;
    margin-bottom: 20px;
    margin-top: 20px;
    cursor: pointer;
}

#view-all:hover {
    background-color: #59aae9;
}

.btn {
    padding: 25px;
    border-radius: 10px;
    margin-right: 15px;
    background-color: transparent;
    backdrop-filter: blur(5px);
    color: white;
    font-weight: 700;
    font-size: 20px;
    border: 1px solid rgba(216, 214, 214, 0.918);
    box-shadow: 0px 0px 4px 1px rgba(216, 214, 214, 0.918);
    cursor: pointer;
}

.btn:hover {
    background-color: antiquewhite;
    color: black;
    backdrop-filter: blur(10px);
}