.pagee {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 99vw;
    height: auto;
    background-color: rgb(47, 47, 47);
    background-repeat: no-repeat;
    background-size: cover;
}

.backToTop {
    background-color:#dae0e6;
    border: none;
    text-decoration: underline;
    color: #0079db;
    cursor: pointer;
    padding-bottom: 109px;
}

.outer-page {
    display: flex;
    background-color: #dae0e6;
    justify-content: center;
    width: 1387px;
    /* border: 1px solid red; */
    /* padding-bottom: 124px; */
}

html {
    background-color: #dae0e6;
}

.posts {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    box-shadow: 0px 0px 4px 3px rgba(216, 214, 214, 0.918);
    position: relative;
    border-radius: 2px;
    margin: 30px 0px 10px;
    width: 800px;
    height: auto;
    background-color: white;
    padding-bottom: 30px;
}

.newCommentEditForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    width: 800px;
    background-color: white;
    border-radius: 2px;
    /* box-shadow: 0px 0px 4px 3px rgba(216, 214, 214, 0.918); */
    margin-top: 5px;
}

.sign-in-or-up {
    margin: 0px 4px;
    text-decoration: none;
    color: #0079db;
}

.not-signed-in-comment {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.li {
   overflow-wrap: normal;
   list-style: none;
   margin-bottom: 10px;
}



.deleteEditBtn {
    display: flex;
    justify-content: flex-end;
}

.img-tag {
    display: flex;
    height: 425px;
    width: 749px;
    border-radius: 2px;
    
}

.btnsDiv {
    display: flex;
    justify-content: flex-end;
}

.post-title {
    display: flex;
    font-size: 14px;
    font-weight: 550;
    margin-top: 10px;
    margin-left: 5px;
    letter-spacing: .3px;
}

.username-span {
    margin-left: 3px;
    font-weight: bold;
}

.post-username {
    display: flex;
    font-size: 12px;
    font-weight: 200;
    margin-top: 10px;
    margin-left: 5px;
    letter-spacing: .3px;
}

#upvote{
    width: 44px;
    height: 25px;
}

#upvote-btn:hover {
    background-color: rgb(228, 228, 228);
    border: 1px solid rgb(228, 228, 228);
}

/* #upvoted{
    color: red;
    width: 25px;
    height: 25px;
} */

#downvote{
    margin: 0px;
    width: 44px;
    height: 25px;
}

#downvote-btn {
    /* margin-top: 10px; */
    height: 39px;
}

#downvote-btn:hover {
    /* margin-top: 10px; */
    background-color: rgb(228, 228, 228);
    border: 1px solid rgb(228, 228, 228);
}

#upvote-btn {
    height: 39px;
}

.left-postt {
    display: flex;
    align-items: center;
    flex-direction: column;
    float: left;
    height: 465px;
    width: 45px;
    border-radius: 2px;
    border-right: 1px solid white;
    background-color: white;
}

.left-postt button {
    border: 1px solid white;
    background-color: white;
}

.left-postt button:hover {
    cursor: pointer;
}

.left-postt img {
    border-radius: 4px;
    height: 30px;
    width: 30px;
    background-color: white;
}

.one-post-main-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 99%;
    width: 744px;
    text-align: center;
}

.one-post-image{
    margin-top: 20px;
    margin-left: 5px;
    height: 425px;
    width: 767px;
    border-radius: 4px;
}

.left-postt .votesss {
    border: 1px solid white;
    background-color: white;
    width: auto;
}

.comment-reply-div {
    margin-left: 10px;
}

.commm { 
    overflow-wrap: anywhere;
    font-weight: 300;
    margin-left: 31px;
    height: fit-content;
    padding-left: 5px;
    margin-top: 5px;
    font-size: 16px;
}

.comment .btnsDiv {
    display: flex;
}

.username-profile-pic {
    display: flex;
    flex-direction: row;
    margin-left: 21px;
    margin-bottom: 2px;
    margin-top: 5px;
}

.username-profile-pic h5 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
}

.profile-pic {
    display: flex;
    /* border: 1px solid red; */
    border-radius: 50%;
    height: 20px;
    width: 20px;
}

.comment {
    display: flex;
    flex-direction: column;
    margin: 0px 2px;
    font-size: 12px;
    background-color: transparent;
    backdrop-filter: blur(5px);
}

.on-comment-username {
    display: flex;
    /* margin-left: 22px; */
    justify-content: center;
    text-align: center;
    font-size: 14px;
    /* margin-bottom: 5px; */
}

.error-div {
    display: flex;
    justify-content: center;
    width: 300px;
    background-color: transparent;
    overflow-wrap:break-word;
    margin: 0;
    padding-right: 20px;
    margin-top: 20px;
}
.errors {
    display: flex;
    float: left;
    justify-content: center;
    background-color: transparent;
    font-size: 12px;
    overflow-wrap: break-word;
    margin: 0px;
    margin-right: 50px;
}

.comment_on_comment{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: right;
}

.idk {
    display: flex;
}

#one-post-edit-btn-icon {
    height: 27px;
    width: 20px;
    /* margin-right: 6px; */
    margin-left: 3px;
    margin-bottom: 3px;
}

#one-post-delete-btn-icon {
    height: 23px;
    width: 22px;
    /* margin-right: 6px; */
    margin-left: 1px;
    margin-bottom: 3px;
}

.comments button {
    display: flex;
    justify-content: center;align-items: center;
    cursor: pointer;
    /* margin-top: 10px; */
    width: 73px;
    height: auto;
    /* margin-left: 2px; */
    font-size: 12px;
    text-align: center;
    padding: 5px;
    color: #626262;
    /* border: 1px solid #e4e3e3; */
}

.vote-div {
    margin-left: 1px;
}

.comments button:hover {
    /* background-color: ; */
    background-color: rgb(228, 228, 228);

}

.comments > h3 {
    margin-left: 62px;
    margin-bottom: 10px;
}

.comments {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    width: 800px;
    margin-top: 20px;
}

.main-feed-containers {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin: 0px 7px;
    width: 800px;
    height: auto;
    margin-bottom: 100px;
}

.post-body {
    display: flex;
    /* justify-content: center; */
    margin: 4px 3px 0px;
    font-size: 16px;
    overflow-wrap: anywhere;
    font-weight: 550;
    font-size: 14px;
    text-align: left;
}

#post-btnsss {
    display: flex;
    justify-content: center;
    border-radius: 20px;
    padding: 5px 10px;
    margin-top: 10px;
    margin-left: 80px;
    border: 1px rgba(0, 0, 0, 0.493) solid;
    border: 1px solid hsl(214, 19%, 93%);
    background-color: white;
    cursor: pointer;
    width: 150px;
}

.comment-form {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    background-color: transparent;
    width: 674px;
    padding: 0px 63px 0px 63px;
    /* margin-bottom: 20px; */
    /* margin-top: 20px; */
    /* margin-left: 41px; */
}

/* .comment-on-comment-form {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    width: 300px;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 41px;
} */

.comment-form h5 {
    display: flex;
    justify-content: flex-start !important;
    margin-bottom: 5px;
    font-weight: 300;
    cursor: default;
}

.comment-form-username {
    margin-left: 3px;
    color: #0079db;
    font-size: 14px;
}

.one-post-btn-div {
    display: flex;
    margin-top: 5px;
    height: 30px;
}


.one-post-btn-div button:nth-child(3) {
    /* padding-right: 20px; */
    /* color: white; */
    width: 58px;
}

.newCommentEditForm form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: transparent;
    width: -webkit-fill-available;
    /* margin-right: 50px; */
    overflow-wrap: break-word;
    overflow-wrap: break-word;
    padding: 6px 10px;
}

/* #f6f7f8 */

.comment-form textarea {
    display: flex;
    resize: none;
    width: 664px;
    height: 130px;
    border-radius: 4px 4px 0px 0px;
    padding: 7px 10px;
    /* border: 1px solid #edeff1; */
    border-top: 1px solid #edeff1;
    border-right: 1px solid #edeff1;
    border-left: 1px solid #edeff1;
    border-bottom: none;
    resize: vertical;
}

/* .comment-on-comment-form textarea {
    display: flex;
    resize: none;
    width: 300px;
    border-radius: 6px;
    padding: 5px;
} */

.comment-textarea-bottom{
    display: flex;
    justify-content: flex-end;
    height: auto;
    width: 684px;
    border: 1px solid #edeff1;
    background-color: #edeff1;
}

#one-post-comment-btn {
    display: flex;
    /* justify-content: flex-end; */
    border-radius: 12px;
    padding: 4px 30px;
    border: 1px rgba(0, 0, 0, 0.493) solid;
    border: 1px solid hsl(214, 19%, 93%);
    background-color: white;
    cursor: pointer;
    text-align: center;
    margin: 2px 8px;
}

#one-post-comment-btn:hover {
    background-color: #f8f8f8;
}

.one-post-username {
    display: flex;
    font-size: 11px;
    font-weight: 300;
    padding: 5px 0px 0px 5px;
}

.one-post-comment-count {
    height: 40px;
    width: 748px;
    /* border: 1px solid red; */
    margin-left: 5px;
}

.comment-count {
    display: flex;
    align-items: center;
    height: 40px;
    font-size: 14px;
    cursor: default;
}

#count {
    padding: 0px 3px 0px 3px;
}

.post-body-text p{
    overflow-wrap: break-word;
}

/* @media screen and (max-width: 500px) {
    .right-container {
        display: none;
    }
} */

.mobile-vote-div {
    display: none;
}

@media screen and (max-width: 1125px){
    .right-container {
        display: none;
    }
}

@media screen and (min-width: 851px){
    /* .votes {
        display: none;
    } */

    .mobile-count {
        display: none;
        /* flex-direction: row; */
        /* width: 400ox; */
    }

    .mobile-votes {
        display: none;
    }
    /* .votesss {
        display: none;
    } */

    /* .mobile-votes-div {
        display: none;
    } */
}

@media screen and (max-width: 850px){

    .mobile-votes-div {
        display: none;
    }
    .votesss {
        display: none;
    }

    .mobile-count {
        display: flex;
        /* flex-direction: row; */
        width: 400px;
    }

    #upvote {
        /* color: red; */
        width: 44px;
        height: 25px;
    }

    #upvote-btn:hover {
        margin-top: 0px;
        background-color: rgb(228, 228, 228);
    }

    .mobile-votes {
        display: flex;
        flex-direction: row;
        width: 50px;
    }

    .votes-label {
        margin-left: 1px;
        margin-right: 5px;
    }

    #upvote-btn {
        display: flex;
        align-items: center;
        border: none;
        background-color: transparent;
        width: 30px;
        /* height: 30px; */
        color: #929596;
        margin-top: 0px;
        /* height: 39px; */
    }

    #upvote{
        /* color: red; */
        width: 44px;
        height: 13px;
    }

    #downvote-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        /* margin-top: 10px; */
        /* height: 39px; */
        border: none;
        background-color: transparent;
        color: #929596;
        width: 30px;
        position: static;
    }

    #downvote{
        margin: 0px;
        width: 44px;
        height: 13px;
    }

    .votesss {
        display: none;
        /* justify-content: center; */
        /* align-items: center; */
        /* height: 20px; */
        /* width: 100%; */
        /* background-color: white; */
        /* border: transparent; */
      }

    .vote-div {
        display: flex;
        flex-direction: center;
        align-items: center;
        width: 50px;
        /* flex-direction: row; */
    }

    .one-post-comment-count {
        height: 40px;
        width: auto;
        /* border: 1px solid red; */
        margin-left: 5px;
      }

    .comment-count {
        display: flex;
        align-items: center;
        height: 40px;
        width: auto;
        font-size: 14px;
        cursor: default;
        
      }

    .main-feed-containers {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        /* margin: 0px 7px; */
        width: 23em;
        height: auto;
        margin: 0px 0px 0px 4px;
        margin-bottom: 100px;
    }

    .one-post-main-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        /* height:auto; */
        width: 21em;
        text-align: center;
    }

    .idk {
        display: flex;
        width: 23em;
    }

    .posts {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        box-shadow: 0px 0px 4px 3px rgb(216 214 214 / 92%);
        position: relative;
        border-radius: 2px;
        margin: 30px 0px 10px;
        width: 23em;
        height: auto;
        background-color: white;
        padding-bottom: 30px;
    }

    .one-post-username {
        display: flex;
        font-size: 11px;
        font-weight: 300;
        padding: 5px 0px 0px 5px;
        width: 21em;
    }

    .newCommentEditForm {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: auto;
        width: 23em;
        background-color: white;
        border-radius: 2px;
        /* box-shadow: 0px 0px 4px 3px rgb(216 214 214 / 92%); */
        margin-top: 5px;
    }
      
    .comments {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: auto;
        width: 23em;
        margin-top: 20px;
    }
    
    .img-tag {
      display: flex;
      height: 17em;
      width: 23em;
      border-radius: 0px;
    }



    .one-post-image {
        margin-top: 5px;
        margin-left: 0px;
        height: auto;
        width: 19.8em;;
        border-radius: 4px;
      }

    .comment-form {
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        /* align-items: center; */
        background-color: transparent;
        width: 15em;
        padding: 0px 0px 0px 0px;
        /* margin-bottom: 20px; */
        /* margin-top: 20px; */
        /* margin-left: 41px; */
      }

    .newCommentEditForm form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: transparent;
        width: fit-content(19em);
        /* margin-right: 50px; */
        overflow-wrap: break-word;
        overflow-wrap: break-word;
        padding: 6px 10px;
      }

    .left-postt {
        display: none;
        /* align-items: center; */
        /* flex-direction: column; */
        /* float: left; */
        /* height: auto; */
        /* width: 46px; */
        /* border-radius: 2px; */
        /* border-right: 1px solid white; */
        /* background-color: white; */
      }

    .comment-form textarea {
        display: flex;
        resize: none;
        width: 24.5em;
        height: 130px;
        border-radius: 4px 4px 0px 0px;
        padding: 7px 10px;
        /* border: 1px solid #edeff1; */
        border-top: 1px solid #edeff1;
        border-right: 1px solid #edeff1;
        border-left: 1px solid #edeff1;
        border-bottom: none;
        resize: vertical;
      }

    .comment-textarea-bottom {
        display: flex;
        justify-content: flex-end;
        height: auto;
        width: 21.65em;
        border: 1px solid #edeff1;
        background-color: #edeff1;
        border-radius: 00px 0px 4px 4px;
    }

    .comment-form h5 {
        display: flex;
        justify-content: center !important;
        margin-bottom: 5px;
        font-weight: 300;
        cursor: default;
        width: auto;
    }

    .comment {
        display: flex;
        flex-direction: column;
        /* margin: 0px 2px; */
        font-size: 12px;
        background-color: transparent;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
    }

    .username-profile-pic {
        display: flex;
        flex-direction: row;
        margin-left: 21px;
        margin-bottom: 2px;
        margin-top: 5px;
    }

    .backToTop {
        /* background-color: #dae0e6; */
        /* border: none; */
        /* text-decoration: underline; */
        /* color: #0079db; */
        /* cursor: pointer; */
        /* padding-bottom: 109px; */
        display: none;
      }
}