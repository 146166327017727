
.community-card {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    border: 2px solid #f6f7f8;
    border-left: none;
    border-right: none;
    height: 100%;
    width: 500px;
}

.community-btn-div {
    display: flex;
}

.community-li {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: 2px solid #f6f7f8;
    background-color: white;
    height: 60px;
    border-bottom: none;
    font-size: 20px;
}

.community-btn {
    background-color: white;
    border: none;
}

.numbered-li {
    margin-left: 20px;
    font-size: 18px;
}

.community-name-btn {
    margin-left: 25px;
    font-size: 18px;
    cursor: pointer;
}

.join-btn-div {
    display: flex;
    margin-right: 30px;
    justify-content: center;
    align-items: center;
}

.join-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 20px;
    width: 50px;
    height: 25px;
    border: none;
    color: white;
    cursor: pointer;
    background-color: #0079d3;
}

