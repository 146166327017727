.signupp {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid red;
    width: 100vw;
    height: 98vh;
    background-image: url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhQk8R5j0_NazbF0KkD7CkooQodlbgy5Apfw&usqp=CAU');
    background-repeat: no-repeat;
    background-size: cover;
}

